import React from 'react'
import SwaggerUIComponent from './Components/SwaggerUIComponent'

const App = () => {
  return (
    <> 
    <SwaggerUIComponent/>
    </>
  )
}

export default App
