import React, { useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './SwaggerUIComponent.css';  // Import the CSS file
import logo from '../assets/Images/image.png';  // Import the image file

const SwaggerUIComponent = () => {
  const [activeTab, setActiveTab] = useState('openapi1');
  const [selectedVersion, setSelectedVersion] = useState('latest');

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
  };

  const getYamlFile = () => {
    if (activeTab === 'openapi1') {
      return selectedVersion === 'latest' ? '/openapilatestuser.yaml' : `/openapi${selectedVersion}user.yaml`;
    } else if (activeTab === 'openapi2') {
      return selectedVersion === 'latest' ? '/openapilatestasto.yaml' : `/openapi${selectedVersion}astro.yaml`;
    } else if (activeTab === 'blogs-api') {
      return '/openapiblog.yaml';
    }
  };

  return (
    <div className="swagger-container">
      <header className="header">
        <div className="logo-container">
          <img
            src={logo}
            alt="Logo"
          />
        </div>
      </header>
      <h1 style={{ textAlign: 'center', width: '100%' }}>API Documentation</h1>
      <div className="version-dropdown">
        <label htmlFor="version-select" style={{margin:'10px',marginLeft:'50px'}}>Select Version: </label>
        <select id="version-select" value={selectedVersion} onChange={handleVersionChange}>
          <option value="latest">Latest Version</option>
          <option value="0.2">Version 0.2</option>
          {/* Add more versions as needed */}
        </select>
      </div>
      <div className="tab-container">
        <button
          onClick={() => setActiveTab('openapi1')}
          style={{ borderRadius: '7px', padding: '7px' }}
        >
          talkndheal-lumen-user
        </button>
        <button
          onClick={() => setActiveTab('openapi2')}
          style={{ borderRadius: '7px', padding: '7px' }}
        >
          talkndheal-astrologer-api
        </button>
        <button
          onClick={() => setActiveTab('blogs-api')}
          style={{ borderRadius: '7px', padding: '7px' }}
        >
          Blogs-Talkndheal-api
        </button>
      </div>
      <SwaggerUI url={getYamlFile()} />
    </div>
  );
};

export default SwaggerUIComponent;